/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <link rel="stylesheet" type="text/css" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,200,300,600,700|Playfair+Display:400,700,900" />
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div id="container">
        {children}
      </div>
      <footer id="page-footer">
        <div className="praise">
          Keep on loving one another as brothers and sisters.<br/>Hebrews 13:1 (NIV)
        </div>
        <div className="attribution">
          13one.org is an independent project not affiliated with any organization.<br/>All content is in the public domain unless otherwise noted.
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
