import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql, navigate } from 'gatsby'

class Header extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showNav: false
    }
  }

  render () {
    const { showNav } = this.state
  
    return (
      <StaticQuery
        query={graphql`
          query {
            allStudiesJson {
              nodes {
                name
                slug
              }
            }
          }
        `}
        render={data => (
          <header
            style={{
              background: `rebeccapurple`,
              marginBottom: `1.45rem`,
            }}
          >
            <div id="hamburger" onClick={() => this.setState({ showNav: !showNav })}>
              <div className={`line top-line ${showNav ? 'active' : ''}`}></div>
              <div className={`line middle-line ${showNav ? 'active' : ''}`}></div>
              <div className={`line bottom-line ${showNav ? 'active' : ''}`}></div>
            </div>
            {showNav && 
              <div id="study-selector" className="completely">
                <ul>
                  {data.allStudiesJson.nodes.map(s => (
                    <li key={s.slug} className="study-option" onClick={() => {
                      localStorage.setItem('lastLesson', s.slug)
                      this.setState({ showNav: false});
                      navigate('/' + s.slug)
                    }}>{s.name}</li>
                  ))}
                </ul>
              </div>
            }
          </header>
        )}
      />
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
